.join{
    width: 100px;
    height: 50px;
    background-color: rgba(87,89,233,1);
    border-radius: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
 
}
.login{
    width: 100px;
    height: 50px;
    background-color: rgba(44,185,176,1);
    border-radius: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
 
}
.join span{
    color: white;
    font-size: 18px;
    text-decoration: underline;
}
.login span{
    color: white;
    font-size: 18px;
    text-decoration: underline;
}
.join-login{
  
    width: 90%;
    margin: 0 auto;
}
.cancel span{
    color: white;
    font-size: 18px;
   
}
.cancel-div{
    padding: 10px;
    background-color: rgba(3,49,83,1);
    margin-top: 40px;
    width:100%;
    border-radius:8px ;
    cursor: pointer;
}