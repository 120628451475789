.join-chat-btn{
    background-color: rgba(3,49,83,1);
    margin-top: 40px;
    width:150px;
    height: 50px;
    font-size: 18px;
    align-items: center;
    color: white;
    border-radius:30px ;
    cursor: pointer;
}

.join-chat-btn-div{
    justify-content: center;
    align-items: center;
    margin-left: 120px;
    margin-bottom: 20px;
}

.group-name-div{
    text-align: center;
}

.group-name{
    font-size: 20px;
    text-align: center;
    justify-items: center;
}

.daak-logo{
    object-fit: cover;
    height: 70px;
    margin-bottom: 7px;
}
