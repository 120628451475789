@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@200;300;400;600;700&display=swap');

.main-div{
    width: 390px;
    margin: 0 auto;
    border: 1px solid gray;
    font-family: 'Nunito Sans', sans-serif;
}

@media (max-width: 413px) and (min-width: 320px)  {
    .main-div{
        width: 100%;
        margin: 0;
    }
}
