.header-top {
  background-color: #033153;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.title-div .title{
color: #F6F6F6;
font-size: 20px;
}