.img-div img {
  width: 100%;
  cursor: pointer;
  height: 260px;
  object-fit: cover;
  background-repeat: no-repeat;
  border-radius: 8px;
}

.vide-div video {
  width: 100%;
  cursor: pointer;
  height: 260px;
  object-fit: cover;
  background-repeat: no-repeat;
  border-radius: 8px;
}

.feeling-div img {
  width: 10%;
  cursor: pointer;
  height: 30px;
  object-fit: contain;
  background-repeat: no-repeat;
}

.feeling-text{
  font-size: 20px;
  color: rgba(153,153,153,1);
  padding-left: 5px;
}

.location-text{
  font-size: 12px;
  color: rgba(3, 49, 83, 1);
  padding-left: 4px;
}

.tagged-text {
  font-size: 12px;
  color: rgba(153,153,153,1);
}

.tagged-user {
  font-size: 12px;
  color: rgba(3, 49, 83, 1);
  /*text-decoration: underline;*/
}

.location-icon{
  font-size: 14px;
  margin-top: 4px;
  padding-left: 3px;
}

.post-text {
  font-size: 14px;
  color: rgba(68, 68, 68, 1);
  letter-spacing: 0.2px;
  overflow: hidden;
  height: 25px;
}

.post-description {
  font-size: 14px;
  color: rgba(68, 68, 68, 1);
  letter-spacing: 0.2px;
}

.see-more {
  font-weight: bold;
  font-size: 14px;
  color: rgba(3, 49, 83, 1);
  letter-spacing: 0.2px;
  cursor: pointer;
}
.like_view_comment {
  font-weight: bold;
  font-size: 13px;
  /*font-family: Nunito Sans;*/
  color: rgba(42, 42, 42, 1);
  padding-bottom: 10px;
  border-bottom: 1px solid #c7c1c1;
}
.like-icon {
  font-size: 11px;
  color: white;
}
.eye-icon {
  margin-right: 2px;
}

.see-post-on {
  font-weight: bold;
  font-size: 16px;
  color: rgba(3, 49, 83, 1);
  text-decoration: underline;
  cursor: pointer;
  font-family: 'Nunito Sans', sans-serif;
}
.rect-img {
  /*margin: 5px;*/
  /*margin-left: -10px;*/
}
.share-text {
  cursor: pointer;
  font-weight: bold;
  font-size: 13px;
  color: rgba(42, 42, 42, 1);
}
.like-button {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 18px;
  width: 18px;
  background-color: #0095d8;
  border-radius: 50%;
}

.total-view {
  line-height: 2px;
}
