.n__days_ago{
    margin-top: -2.5px;
	font-size: 11px;
	color: rgba(153,153,153,1);
}
.owner-name{
    font-weight: bold;
	font-size: 16px;
	font-family: 'Nunito Sans', sans-serif;
	font-style: normal;
	text-align: left;
	color: rgba(0,0,0,1);
}

.profile-image {
	width: 55px;
	height: 51px;
	border-radius: 48px;
}