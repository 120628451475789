.title_kp {
  white-space: nowrap;
  line-height: 33px;
  /* font-family: Nunito Sans; */
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  color: rgba(0, 0, 0, 1);
}
.title_kr {
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  color: rgba(87, 89, 233, 1);
  letter-spacing: 0.2px;
  text-decoration: underline;
}
.title_kr_right {
  font-weight: normal;
  color: rgba(68, 68, 68, 1);
}
.join-section{
margin-bottom: 35px;
font-size: 14px;
color: rgba(0,0,0,1);

}