.comments-title{
  color: #033153;  
  font-size: 20px;
  font-weight: bold;
  font-family: 'Nunito Sans', sans-serif;
}
.name{
    margin-top: -4px; 
    font-style: normal;
	font-weight: bold;
	font-size: 16px;
	color: rgba(0,0,0,1);
    font-family: 'Nunito Sans', sans-serif;
}
.date{
    /* margin-top: -3px; */
    font-style: normal;
	font-weight: bold;
	font-size: 11px;
	color: rgba(153,153,153,1);
    font-family: 'Nunito Sans', sans-serif;
}
.comment{
    /* margin-top: -3px; */
    font-size: 14px;
	color: rgba(68,68,68,1);
	letter-spacing: 0.2px;
    font-family: 'Nunito Sans', sans-serif;
}
.rects{
   font-weight: 500;
	font-size: 16px;
	color: rgba(153,153,153,1);
    font-family: 'Nunito Sans', sans-serif;
}
.comment-div{
    border-bottom: 1px solid #c7c1c1
}
.liked-icon{
    cursor: pointer;
}
.reply-icon{
    cursor: pointer;
}

.profile-image {
    width: 55px;
    height: 51px;
    border-radius: 48px;
}